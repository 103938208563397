import '@hotwired/turbo-rails';
import 'regenerator-runtime/runtime';

import { metaTagContent } from 'utils/common';
import 'utils/polyfills';
import 'controllers';
import 'stylesheets/connect';

const sentryDsn = metaTagContent('sentry-dsn');

if (sentryDsn) {
  // prettier-ignore
  Promise.all([
        import(/* webpackChunkName: 'sentry-browser' */ '@sentry/browser'),
        import(/* webpackChunkName: 'sentry-tracing' */ '@sentry/tracing'),
      ]).then(([{ init }, { Integrations }]) => {
        init({
          dsn: sentryDsn,
          integrations: [new Integrations.BrowserTracing()],
          tracesSampleRate: 1.0,
          whitelistUrls: [/teamtailor\.com/],
        });
      });
}
